.project-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.project-details {
    background: rgb(44, 44, 44);
    padding: 20px;
    border-radius: 8px;
    max-width: 150vh;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
}

.project-details a {
    color: #f1f1f1;
    text-decoration: none;
}

.close-button {
    position: absolute;
    top: 11px;
    right: 10px;
    background: #cf3e3e;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.close-button:hover {
    background: #900a0a;
    transition: all 0.3s;
}

.project-details .img-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}


.project-details .img-container img {
    width: 30%;
    border-radius: 8px;
}

.technologies {
    margin-top: 20px;
}

.chips {
    display: flex;
    max-width: 20%;
}
