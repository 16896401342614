.card-link{
    position: fixed;
    top: 88%;
    left: 93%;
    z-index: 1000;
    button{
        background-color:rgba(118, 74, 166, 1);
        border: 1px solid #000;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: 0.75s;
        &:hover{
            background-color: rgba(255, 88, 139, 1);
            color: #fff;
        }
    }
}

@media (max-width: 767px) {
    .card-link{
        top: 90%;
        left: 80%;
    }
    
}