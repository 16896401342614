/* Styles pour PC */
.project_detail {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap : 20px;
    }

.project_detail >* :hover{
    .project_detail{
        background-color: aquamarine;
    }
}