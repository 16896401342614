body {
  color: #ffffff;
}

/*------------------- CSS Accueil (pour grand écran) -------------------*/

h1{
  color: rgb(221, 114, 114);
}

.contenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  background-color: rgba(14, 22, 33);
  width: 100%;
  min-height: 90vh;
  font-size: 1.2em;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/*------------------- Media Queries pour tablettes et petits PC -------------------*/
@media (max-width: 1400px) {
  .arrondi h2 {
    font-size: 2em; /* Réduire la taille du texte */
  }

  .blob-acceuil, .blob-corp-right {
    width: 200px; /* Réduction de la taille des blobs */
  }

  .info_personnel, .info_professionnel {
    max-width: 100%;
    margin-left: 5%;
    img{
      height: 15vh;
    }
    video {
      height: 15vh;
    }
  }

  .contenu {
    font-size: 1em; /* Réduire légèrement la taille du texte */
  }

  .arrow span {
    width: 2vw; /* Agrandir légèrement la flèche */
    height: 2vw;
  }
}

/*------------------- Media Queries spécifiques aux téléphones -------------------*/
@media (max-width: 768px) {
  .arrondi {
    font-size: 1.5em; /* Réduire encore plus la taille du texte */
    justify-content: center;
  }

  .blob-acceuil, .blob-corp-right, .blob-corp-left {
    width: 100px; /* Taille encore plus petite des blobs */
  }

  .info {
    flex-direction: column; /* Passage à une direction de colonne pour les téléphones */
    align-items: center;
  }

  .info_personnel, .info_professionnel {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
    img {
      height: 5vh;
    }
  
    video {
      height: 10vh;
    }
  }

  .accordion {
    display: block;
  }

  .contenu {
    font-size: 0.9em; /* Taille du texte encore plus petite */
    padding: 10%;
  }

  .arrow {
    top: 85%; /* Ajuster la position de la flèche */
  }

  .arrow span {
    width: 3vw;
    height: 3vw;
  }

  .project_detail {
    grid-template-columns: repeat(auto-fill, minmax(300px, 4fr));
  }
}
