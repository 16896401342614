.projectbody {
  position: relative;
  max-width: 55vh;
  min-height: 35vh;
  transform: scale(1);
  transition: all 0.5s;

  padding: 1rem;
  font-size: 14px;
  font-weight: bold;
  /* text-transform: uppercase; */
  color: #ffffff;
  background-color: #27272767;
  border-radius: 10px;

  box-shadow: 0 0 10px rgba(119, 119, 119, 0.591);  
}

.projectbody:hover {
  box-shadow: 0 0 20px rgba(119, 119, 119, 0.591);
  transform: scale(1.05);
  transition: all 0.5s;

  background-color: #363535;
}

p {
    margin-bottom: 0.8em;
  }

.details-button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  
  background-color: #272727;
  color: #f1f1f1;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.details-button:hover {
    background-color: #f1f1f1;
    color: #272727;
    transition: 0.5s;
  }


.projectbody .img-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  img{
    width: 25%;
    flex: 1 1 auto;
    border-radius: 8px;
  }
}